export default function embedVideo() {
    document.addEventListener('DOMContentLoaded', function (event) {
        // Find all oembed elements with a 'url' attribute
        const oembedElements = document.querySelectorAll('oembed[url]');
        console.log(oembedElements);

        oembedElements.forEach(function (element) {
            const url = element.getAttribute('url');
            let videoId = null; // Initialize videoId as null

            if (!url) return; // Exit if no URL is found
            videoId = extractYouTubeId(url) || extractVimeoId(url); // Extract IDs for both platforms

            if (videoId) {
                let iframe = document.createElement('iframe');
                // Determine the correct src based on the platform
                if (url.includes('youtube.com') || url.includes('youtu.be')) {
                    // Create the iframe element for the YouTube video
                    iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}`);
                    iframe.title = "YouTube video player";
                } else if (url.includes('vimeo.com')) {
                    // Create the iframe element for the Vimeo video
                    iframe.setAttribute('src', `https://player.vimeo.com/video/${videoId}`);
                    iframe.title = "Vimeo video player";
                }

                // Set common attributes for the iframe
                iframe.setAttribute('width', '560'); // Set width
                iframe.setAttribute('height', '315'); // Set height
                iframe.setAttribute('frameborder', '0');
                iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
                iframe.setAttribute('allowfullscreen', 'true');
                iframe.loading = "lazy";
                iframe.style.aspectRatio = "16/9"; // Maintain aspect ratio
                iframe.style.width = "100%"; // Responsive width
                iframe.style.display = "block";
                iframe.style.height = "auto";
                iframe.style.margin = "1.5rem auto"; // Center the iframe

                // Replace the oembed element with the iframe
                if (element.parentNode) {
                    element.parentNode.replaceChild(iframe, element);
                }
            }
        });

        // Function to extract the YouTube video ID from various URL formats
        function extractYouTubeId(url) {
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
            const matches = url.match(regex);
            return matches ? matches[1] : null; // Return the extracted ID or null
        }

        // Function to extract the Vimeo video ID from various URL formats
        function extractVimeoId(url) {
            const regex = /(?:https?:\/\/)?(?:www\.)?(?:vimeo\.com\/)([0-9]+)/;
            const matches = url.match(regex);
            return matches ? matches[1] : null; // Return the extracted ID or null
        }
    });
}
