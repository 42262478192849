function headerAlertModal() {
  window.onload = function () {
    const alertModal = document.getElementById("alertModal");

    // Check if we have a modal first:
    if (alertModal === null) {
      return;
    }

    if (sessionStorage.getItem("isAlertActive") === "false") {
      alertModal.setAttribute("active", "false");
    }

    const alertToggleButton = document.querySelector(".alertToggle");
    alertToggleButton.addEventListener("click", function () {
      if (alertModal.getAttribute("active") === "true") {
        // Add animate class
        alertModal.classList.add("animate");
        // Remove animate class after 400ms, then set active to false
        setTimeout(function () {
          alertModal.classList.remove("animate");
          alertModal.setAttribute("active", "false");
          sessionStorage.setItem("isAlertActive", "false");
        }, 400);
      }
    });
  };
}

export default headerAlertModal;
