// First order of buisness -> Remove no-js class from html element
document.documentElement.classList.remove("no-js");

// Global components
import stickyHeader from "./global/stickyHeader.js";
stickyHeader();

import headerAlertPanel from "./global/headerAlertPanel.js";
headerAlertPanel();

import navigationAndSearch from "./global/navigationAndSearch.js";
navigationAndSearch();

import sidebarNavigation from "./global/sidebarNavigation.js";
sidebarNavigation();

import pagination from "./global/pagination.js";
pagination();

import leaveSite from "./global/leaveSite.js";
leaveSite();

import embedVideo from "./global/embedVideo.js";
embedVideo();

// Blocks
import "./blocks/coverBlock.js";

import "./blocks/faqBlock.js";

import "./global/pageHero.js";

import "./templates/findShelter.js";
