import isMobileView from "../utillity/viewportHelper.js";
import generateRandomString from "../utillity/generateRandomString.js";

export default function navigationAndSearch() {
  const page = document.querySelector(".page");
  const menuButtons = document.querySelectorAll(
    ".menuButtonItem .menuButtonToggle"
  );

  const submenuMap = new Map();
  let menuToggle;
  let searchToggle;
  let svgUseElement;

  async function handleViewportChange(event) {
    const { wasMobile, isMobile } = event.detail;

    if (wasMobile !== isMobile) {
      await closeAllSubmenus(true); // Use the forceClose flag
      closeSearch();
      closeMobileMenu();

      // Do any specific logic for transitioning between views here
      /* if(isMobile) {} else {} */
    }
  }

  async function closeAllSubmenus(forceClose = false) {
    const closePromises = Array.from(menuButtons).map((button) =>
      closeMenu(button, forceClose)
    );
    await Promise.all(closePromises);
  }

  function closeMenu(button, forceClose = false) {
    return new Promise((resolve, reject) => {
      const submenu = submenuMap.get(button);

      if (!submenu) {
        resolve();
        return;
      }

      if (forceClose) {
        submenu.classList.remove("active", "closing");
        button.setAttribute("aria-expanded", "false");
        page.classList.remove("mainNavigationActive");
        resolve();
        return;
      }

      if (submenu.classList.contains("active")) {
        submenu.addEventListener("transitionend", function handler(event) {
          if (event.propertyName !== "opacity") return;
          submenu.classList.remove("active", "closing");
          submenu.removeEventListener("transitionend", handler);
          resolve();
          page.classList.remove("mainNavigationActive");
        });

        submenu.classList.add("closing");
        button.setAttribute("aria-expanded", "false");
      } else {
        resolve();
      }
    });
  }

  function openMenu(button) {
    const submenu = submenuMap.get(button);
    submenu.classList.add("active");
    button.setAttribute("aria-expanded", "true");

    // Add the class to the page when the submenu is opened
    page.classList.add("mainNavigationActive");
  }

  async function toggleMenu(event) {
    event.stopPropagation();
    const button = event.target.closest(".menuButtonToggle");
    const parentItem = button.closest(".menuButtonItem");
    const submenu = parentItem.querySelector(".menuSubmenuItem");

    if (submenu) {
      if (submenu.classList.contains("active")) {
        await closeMenu(button);
      } else {
        if (!isMobileView()) {
          // Check if not in mobile view
          await closeAllSubmenus();
        }
        openMenu(button);
      }
    }
  }

  function initializeMenuButtons() {
    menuButtons.forEach((button) => {
      const parentItem = button.closest(".menuButtonItem");
      const submenu = parentItem.querySelector(".menuSubmenuItem");

      if (submenu) {
        // Generate random ID for each button and enhance the menu with aria-controls and aria-expanded attributes
        const submenuID = `submenu-${generateRandomString(5, {
          includeUppercase: true,
        })}`;
        submenu.setAttribute("id", submenuID);

        // Set up aria-controls on the button
        button.setAttribute("aria-controls", submenuID);
        // Set up aria-expanded on the button
        button.setAttribute("aria-expanded", "false");

        // Save submenu in the map for later use
        submenuMap.set(button, submenu);
      }
    });
  }

  function setupMenuEventListeners() {
    menuButtons.forEach((button) => {
      button.addEventListener("click", toggleMenu);
    });

    document.addEventListener("click", (event) => {
      if (!isMobileView()) {
        const isClickInsideMenu = Array.from(menuButtons).some((button) =>
          button.contains(event.target)
        );
        if (!isClickInsideMenu) {
          closeAllSubmenus();
        }
      }
    });

    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        closeAllSubmenus();
      }
    });
  }

  // Handle Search
  function openSearch() {
    searchToggle.setAttribute("aria-expanded", "true");

    const newLabel = searchToggle.getAttribute("data-close-label");
    searchToggle.setAttribute("aria-label", newLabel);

    svgUseElement.setAttribute("xlink:href", "#iconClose");

    document.body.classList.add("searchActive");

    const spanElement = searchToggle.querySelector("span");
    spanElement.textContent = "Stäng";

    // Get the search input field and set focus on it after a brief delay
    setTimeout(() => {
      const searchInput = document.querySelector("#desktopSearchBar");
      if (searchInput) {
        searchInput.focus();
      }
    }, 200); // Adjust the delay as needed
  }

  function closeSearch() {
    searchToggle.setAttribute("aria-expanded", "false");

    const newLabel = searchToggle.getAttribute("data-open-label");
    searchToggle.setAttribute("aria-label", newLabel);

    svgUseElement.setAttribute("xlink:href", "#iconSearch");

    document.body.classList.remove("searchActive");

    const spanElement = searchToggle.querySelector("span");
    spanElement.textContent = "Sök";
  }

  function toggleSearch(event) {
    if (event) event.stopPropagation();

    const isExpanded = searchToggle.getAttribute("aria-expanded") === "true";

    if (isExpanded) {
      closeSearch();
    } else {
      closeAllSubmenus();
      openSearch();
    }
  }

  function setupSearchEventListeners() {
    searchToggle = document.querySelector(".searchToggle");

    if (searchToggle) {
      svgUseElement = searchToggle.querySelector(".icon use");
      const desktopSearch = document.querySelector("#desktopSearch-cP4zJ");

      if (!searchToggle || !desktopSearch || !svgUseElement) {
        return;
      }

      searchToggle.addEventListener("click", toggleSearch);

      document.addEventListener("click", (event) => {
        const isClickInside =
          desktopSearch.contains(event.target) ||
          searchToggle.contains(event.target);

        if (
          !isClickInside &&
          searchToggle.getAttribute("aria-expanded") === "true"
        ) {
          closeSearch();
        }
      });
    }
  }

  // Handle Mobile menu
  function openMobileMenu() {
    menuToggle.setAttribute("aria-expanded", "true");
    document.querySelector('.headerButtons .mobileTopButtons').classList.add('hidden');

    const svgUseElement = menuToggle.querySelector(".icon use");
    if (svgUseElement) {
      svgUseElement.setAttribute("xlink:href", "#iconClose");
    }

    document.body.classList.add("mainMenuActive");
  }

  function closeMobileMenu() {
    menuToggle.setAttribute("aria-expanded", "false");
    document.querySelector('.headerButtons .mobileTopButtons').classList.remove('hidden');

    const svgUseElement = menuToggle.querySelector(".icon use");
    if (svgUseElement) {
      svgUseElement.setAttribute("xlink:href", "#iconNav");
    }

    document.body.classList.remove("mainMenuActive");
  }

  function toggleMobileMenu(event) {
    const isExpanded = menuToggle.getAttribute("aria-expanded") === "true";

    if (isExpanded) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  }

  function setupMobileMenuEventListeners() {
    menuToggle = document.querySelector(".menuToggle");
    if (menuToggle) {
      menuToggle.addEventListener("click", toggleMobileMenu);
    }
  }

  window.addEventListener("viewportChange", handleViewportChange);

  // Initialize the menu buttons
  initializeMenuButtons();
  setupMenuEventListeners();
  setupSearchEventListeners();
  setupMobileMenuEventListeners();
}
