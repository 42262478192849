export default function stickyHeader() {
  const className = 'bodyScrolled';
  const page = document.querySelector('.page');

  // Use the existing bodyScrollObserver div
  const headerScrollObserver = document.getElementById('bodyScrollObserver');

  var observer = new IntersectionObserver(function(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (page.classList.contains(className)) {
          page.classList.remove(className);
        }
      } else {
        if (!page.classList.contains(className)) {
          page.classList.add(className);
        }
      }
    });
  });

  observer.observe(headerScrollObserver);
}