import generateRandomString from "../utillity/generateRandomString.js";

const coverBlocks = document.querySelectorAll(".coverBlock");

if (coverBlocks.length > 0) {
  /**
   * This is a MediaQueryList object representing the results of the specified media query string,
   * which checks whether the user has requested the system minimize the amount of animation or motion it uses.
   */
  const reducedMotionQuery = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  );

  /**
   * These are SVG path strings for the play and pause icons.
   */
  const playPath = 'M19.634 12.15a1 1 0 0 1 0 1.7L1.528 25.106A1 1 0 0 1 0 24.257V1.743a1 1 0 0 1 1.528-.85l18.106 11.259Z';
  const pausePath = 'M0 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v24a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1Z M13 1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v24a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1V1Z';

  /**
   * Creates a SVG element and returns it after setting its attributes.
   * @returns {SVGElement} SVG element
   */
  function createSvg() {
    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("aria-hidden", "true");
    svg.setAttribute("fill", "currentColor");
    svg.setAttribute("viewBox", "0 0 21 26");
    return svg;
  }

  /**
   * Creates a SVG path and returns it after appending it to the passed SVG element.
   * @param {SVGElement} svg - The SVG element to which the path is appended.
   * @returns {SVGPathElement} SVG path element
   */
  function createSvgPath(svg) {
    let path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    svg.appendChild(path);
    return path;
  }

  /**
   * Creates a video control button with SVG icon and returns them.
   * @returns {{button: HTMLButtonElement, path: SVGPathElement}} An object containing the created button and SVG path elements.
   */
  function createVideoControl() {
    let button = document.createElement("button");
    button.type = "button";
    button.className = "videoToggle";
    button.setAttribute("aria-label", "Toggle video");

    let svg = createSvg();
    let path = createSvgPath(svg);

    button.appendChild(svg);
    return { button, path };
  }

  /**
   * Iterates over each 'coverBlock' and implements the control behavior.
   */
  coverBlocks.forEach((coverBlock) => {
    const heroTitle = coverBlock.querySelector(".title");
    const heroTitleID = `heroTitle-${generateRandomString(5, {
      includeUppercase: true,
    })}`;
    heroTitle.setAttribute("id", heroTitleID);
    coverBlock.setAttribute("aria-labelledby", heroTitleID);

    const videoElement = coverBlock.querySelector(".videoBackground");

    /** 
     * Check if videoElement exists.
     */
    if (videoElement) {
      /**
       * Check if the video element is present and if it can play a valid format.
       */
      if (
        videoElement.canPlayType("video/mp4") ||
        videoElement.canPlayType("video/webm") ||
        videoElement.canPlayType("video/ogg")
      ) {
        videoElement.removeAttribute("controls");
    }

      const { button: videoControlButton, path: videoControlPath } =
        createVideoControl();

      const heroControls = document.createElement("div");
      heroControls.className = "heroControls";
      heroControls.appendChild(videoControlButton);
      coverBlock.prepend(heroControls);

      const videoID = `video-${generateRandomString(5, {
        includeUppercase: true,
      })}`;
      videoElement.setAttribute("id", videoID);
      videoControlButton.setAttribute("aria-controls", videoID);

      /**
       * Checks for the 'reduced motion' setting and updates the video state and the button icon accordingly.
       */
      const checkReducedMotion = () => {
        if (reducedMotionQuery.matches) {
          videoElement.pause();
          videoControlPath.setAttribute("d", playPath);
        } else {
          if (videoElement.paused) {
            videoElement.play();
          }
          videoControlPath.setAttribute("d", pausePath);
        }
      };

      /**
       * Toggles the playing state of the video and updates the button icon.
       */
      const togglePlayPause = () => {
        if (videoElement.paused) {
          videoElement.play();
          videoControlPath.setAttribute("d", pausePath);
        } else {
          videoElement.pause();
          videoControlPath.setAttribute("d", playPath);
        }
      };

      /**
       * Add event listeners for the 'change' event on 'reducedMotionQuery' and the 'click' event on 'videoControlButton'.
       */
      reducedMotionQuery.addEventListener("change", checkReducedMotion);
      videoControlButton.addEventListener("click", togglePlayPause);
      checkReducedMotion();
    }
  });
}
