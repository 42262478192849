
// Select all elements with the class 'showAll' that are within elements with the class 'openingHours'
const showAllBtns = document.querySelectorAll('.openingHours .showAll');

// Iterate over each 'showAll' button
if (showAllBtns) {
    showAllBtns.forEach(button => {
        button.addEventListener('click', () => {
            button.classList.toggle('show');
            const parentElement = button.parentElement;
            const schedules = parentElement.querySelectorAll('.dateItem');
    
            // Iterate over each 'schedule' element
            schedules.forEach(schedule => {
                schedule.classList.toggle('show');
            });
        });
    });
}

// Move the input search label on click
const searchField = document.querySelector('.searchField');

if (searchField) {
    searchField.addEventListener('click', () => {
        searchField.classList.add('moveLabel');
    });

    // Function to handle clicks outside searchField
    const handleClickOutside = (event) => {
        if (!searchField.contains(event.target)) {
            searchField.classList.remove('moveLabel');
        }
    };

    document.addEventListener('click', handleClickOutside);
}

// Open/close search filters in mobile
const filterToggle = document.querySelectorAll('.filterBox h3');

if (filterToggle) {
    filterToggle.forEach(btn => {
        btn.addEventListener('click', () => {
            const parentElement = btn.parentElement;
            parentElement.classList.toggle('show');
        });
    });
}