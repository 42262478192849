export default function sidebarNavigation() {
  const sidebar = document.querySelector("#sidebar");

  if (sidebar) {
    const openLabel = sidebar.dataset.openLabel;
    const closeLabel = sidebar.dataset.closeLabel;

    const dropdownToggles = sidebar.querySelectorAll(".dropdownToggle");

    function toggleSubmenu(toggleButton) {
      const submenuContainer = toggleButton.nextElementSibling;
      const isExpanded =
        submenuContainer.getAttribute("data-expanded") === "true";

      // Update data-expanded attribute
      submenuContainer.setAttribute("data-expanded", !isExpanded);

      // Update aria-expanded and aria-label attributes
      toggleButton.setAttribute("aria-expanded", !isExpanded);
      toggleButton.setAttribute(
        "aria-label",
        !isExpanded ? closeLabel : openLabel
      );
    }

    // Attach event listeners
    dropdownToggles.forEach((toggle) => {
      toggle.addEventListener("click", () => toggleSubmenu(toggle));
    });

    // Function to expand all ancestor submenuContainers
    function expandAncestors(element) {
      let parentLi = element.closest('li.hasSubmenu');
      while (parentLi) {
        const submenuContainer = parentLi.querySelector('.submenuContainer');
        if (submenuContainer) {
          submenuContainer.setAttribute('data-expanded', 'true');
          const toggleButton = parentLi.querySelector('.dropdownToggle');
          if (toggleButton) {
            toggleButton.setAttribute('aria-expanded', 'true');
            toggleButton.setAttribute('aria-label', closeLabel);
          }
        }
        parentLi = parentLi.parentElement.closest('li.hasSubmenu');
      }
    }

    // Find all <a> tags with aria-current="page" inside elements with class hasSubmenu
    const currentLinks = sidebar.querySelectorAll('.hasSubmenu a[aria-current="page"]');
    currentLinks.forEach((link) => {
      expandAncestors(link);
    });
  }
}
