// Function to handle button click event
export default function leaveSite() {
    // Get all elements with the class "leaveButton"
    const leaveButtons = document.querySelectorAll('.leaveButton');
    
    // Loop through each link and attach the click event listener
    leaveButtons.forEach(function(link) {
        link.addEventListener('click', function(event) {
            event.preventDefault(); // Prevent the default behavior of the anchor tag

            // Replace the current entry in the browser's history stack
            window.history.replaceState(null, null, '');

            // Redirect the user to another website
            window.location.href = 'https://google.com';
        });
    });
}
